import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "components/Layout";

//const AuditTrail = lazy(() => import("../features/audit_trail_page/presentation"));
const AuditTrail = lazy(() => import("pages/AuditTrial/AuditTrial"));
const AuthRoute = lazy(() => import("components/AuthRoute/AuthRoute"));
// const PricerPage = lazy(() =>
//   import("../features/pricer_page/components/PricerGridComponent")
// );
const Home = lazy(() => import("pages/Home/Home"));
const NewPricer = lazy(() => import("pages/Pricer/Pricer"));
const LoginPage = lazy(() => import("pages/LoginPage/LoginPage"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const TeamRun = lazy(() => import("pages/TeamRun"));
const Profile = lazy(() => import("pages/Profile"));

/**
 *  Used for share paths in component to prevent write or redirect path static in code
 */
export const FULL_ROUTES_PATH = {
  // HOME: {
  //   index: "/",
  // },
  SIGN_IN: {
    index: "/login",
  },
  PASSWORD: {
    ResetPassword: "/reset-password",
  },
  // AUDIT_TRAIL: {
  //   index: "/audit-trail",
  //   auditDetails: "/audit-trail/:id",
  // },
  PRICER: {
    newPricer: "/new-pricer",
    index: "/",
  },
  // TEAM_RUN: {
  //   index: "/team-run",
  // },
  PROFILE: {
    index: "/profile",
  },
  ERROR: {
    500: "/internal-error",
    404: "/page-not-found",
    403: "/unexpected-error",
  },
};

/**
 * Use this component for any new section of routes (any config object that has a "routes" property
 */
export const RenderRoutes = ({ routes }) => {
  return (
    <Routes>
      {routes.map((route) => {
        return route.auth ? (
          <Route
            key={route.key}
            path={route.path}
            element={
              <AuthRoute>
                <Layout>{route.component}</Layout>
              </AuthRoute>
            }
          />
        ) : (
          <Route key={route.key} path={route.path} element={route.component} />
        );
      })}
    </Routes>
  );
};

const ROUTES = [
  // {
  //   path: FULL_ROUTES_PATH.HOME.index,
  //   key: "home",
  //   auth: true,
  //   component: <Home />,
  // },
  {
    path: FULL_ROUTES_PATH.SIGN_IN.index,
    key: "sign_in",
    component: <LoginPage />,
  },
  {
    path: FULL_ROUTES_PATH.SIGN_IN.index,
    key: "sign_in",
    component: <LoginPage />,
  },
  {
    path: FULL_ROUTES_PATH.PASSWORD.ResetPassword,
    key: "reset_password",
    component: <ResetPassword />,
  },
  // {
  //   path: FULL_ROUTES_PATH.AUDIT_TRAIL.index,
  //   key: "audit-trail",
  //   auth: true,
  //   component: <AuditTrail />,
  // },
  // {
  //   path: FULL_ROUTES_PATH.AUDIT_TRAIL.auditDetails,
  //   key: "audit-trail-details",
  //   auth: true,
  //   component: <AuditTrail />,
  // },
  {
    path: FULL_ROUTES_PATH.PRICER.index,
    key: "pricer",
    auth: true,
    component: <NewPricer />,
  },
  // {
  //   path: FULL_ROUTES_PATH.TEAM_RUN.index,
  //   key: "team-run",
  //   auth: true,
  //   component: <TeamRun />,
  // },
  {
    path: FULL_ROUTES_PATH.PROFILE.index,
    key: "profile",
    auth: true,
    component: <Profile />,
  },
];

export const ROUTES_PATH = FULL_ROUTES_PATH;

export default ROUTES;
