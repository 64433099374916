import React,{Suspense, lazy} from "react";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ErrorBoundary } from "react-error-boundary";
import ROUTES, { RenderRoutes } from "./routes";
import GenericFallback from "components/GenericFallback";
import PreLoadPagesSpin from "components/PreLoadPagesSpin";
// Ag Grid Style
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

const GlobalState = lazy(() => import("lib/contexts/GlobalContext"));
const queryClient = new QueryClient({});

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ GenericFallback }>
      <Suspense fallback={<PreLoadPagesSpin />}>
        <QueryClientProvider client={queryClient}>
          <GlobalState>
            <main id="content-root">
              <RenderRoutes routes={ ROUTES } />
            </main>
          </GlobalState>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
