import {useEffect} from "react";

const GenericFallback = (props) => {
  useEffect(() => {
    if (!props.error)
      return;

    // log error
    console.error(props.error);

    // if (process.env.REACT_APP_CUSTOM_ENV !== "dev") {
    //   // redirect to 500 page
    //   window.location.replace(ROUTES_PATH.ERROR[500]);
    // }
  }, [props.error]);

  return null;
};


export default GenericFallback;
