import React from 'react';
import {Layout as AntdLayout} from "antd";
import PropTypes from "prop-types";
import NavBar from 'components/NavBar';

const { Content, Footer } = AntdLayout;

const Layout = (props) => {
  return (
    <React.Suspense fallback={<div />}>
    <AntdLayout>
      {/* Header */}
      <NavBar/>

      {/* Content */}
      <Content style={{backgroundColor: 'white',paddingTop: 20}}>
        {props.children}
      </Content>

      {/* Footer */}
      {
        props.footer && (
          <Footer>
            {/* Add Footer When Needed */}
          </Footer>
        )
      }
    </AntdLayout>
    </React.Suspense>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  noFooter: PropTypes.bool
};

Layout.defaultProps = {
  noFooter: false
};

export default Layout;
