import { GEEKS_USER } from "lib/contexts/GlobalContext";

export const validateEmail = (email) => {
  return String(email)
  .toLowerCase()
   .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

/**
 * Method used to remove
 *
 * @returns {boolean}
 */
export const clearUserInfo = () => {
  localStorage.removeItem(GEEKS_USER);

  return true;
};
