import axios from "axios";
import { GEEKS_USER } from "lib/contexts/GlobalContext";
import { clearUserInfo } from "lib/helpers/GeneralFuncHelpers";
import { toast } from "lib/helpers/utilities";

// write a method to get token from local storage

// Common Axios Instance Config
  
const axiosConfig = {               
  // baseURL: "http://localhost/aratus_backend_laravel/public/api", // Local      
  // baseURL: "https://stg.aratus-backend.geeksjo.com/public/api", // Development      
  baseURL: "https://api-europe.aratus.net/api",   // Production
};                     
         

// Create Default Axios Instance
const instance = axios.create(axiosConfig);

// Create Default Axios Instance for actions without Interceptors
const instanceWithoutInterceptors = axios.create(axiosConfig);

// set Common header for without authorization issue....
instanceWithoutInterceptors.interceptors.request.use((config) => {
  // Add common request header
  config.headers["Accept-Language"] = "en"; // Content Accept Language
  return config;
});

// Set ID-token as an authorization header for all request when its exists
instance.interceptors.request.use((config) => {
  // Get user info
  const userToken = JSON.parse(localStorage.getItem(GEEKS_USER))?.token;

  // If userInfo
  if (userToken) {
    config.headers["Authorization"] = `Bearer ${userToken}`; // fetch idToken
  }

  return config;
});

// Add interceptors for validate authorization
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    toast(error.response.data.message, "error");

    if(error.response.status === 401) {
      clearUserInfo();
      return;
    }
    return Promise.reject(new Error(error));
  },
);

// Export Axios with Auth Headers
export default instance;

// Export Axios Without instance
export { instanceWithoutInterceptors as axiosWI };
