import React, { useEffect, useState, useCallback, useContext, useMemo } from "react";
import classnames from 'classnames';
import { Menu, Divider, Avatar, Tabs } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
// Helpers
import { ROUTES_PATH } from "routes";
import { GlobalContext } from "lib/contexts/GlobalContext";
// Styles
import './Navbar.scss';

const { TabPane } = Tabs;

const NAVBAR_NAMES = {
  HOME        : "Home",
  PRICER      : "Pricer",
  NEW_PRICER  : "Pricer Revamp",
  TEAM_RUN    : "Team Run",
  AUDIT_TRAIL : "Audit Trail",
  ARATUS      : "Aratus",
  LOGOUT      : "Logout",
  PROFILE     : "Profile"
};

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {setLoadingOverlay, handleLogout, globalState:{loginInfo}} = useContext(GlobalContext);
  const [activeTab, setActiveTab] = useState("");
  const handleOnLogout = useCallback(()=> {
    setLoadingOverlay(true);
    setTimeout(()=>{
      handleLogout();
      setLoadingOverlay(false);
    },1000);
  },[]);

  const tabsText = useMemo(()=>{
    return [
      // { label: NAVBAR_NAMES.HOME      },
      { label: NAVBAR_NAMES.PRICER      },
      // { label: NAVBAR_NAMES.TEAM_RUN    },
      // { label: NAVBAR_NAMES.AUDIT_TRAIL },
    ]
  },[]);

  const onTabClick = useCallback((tabClicked)=>{
    setActiveTab(tabClicked);
    // if(tabClicked === NAVBAR_NAMES.HOME)      navigate(ROUTES_PATH.HOME.index);
    if(tabClicked === NAVBAR_NAMES.PRICER)      navigate(ROUTES_PATH.PRICER.index);
    // if(tabClicked === NAVBAR_NAMES.TEAM_RUN)    navigate(ROUTES_PATH.TEAM_RUN.index);
    // if(tabClicked === NAVBAR_NAMES.AUDIT_TRAIL) navigate(ROUTES_PATH.AUDIT_TRAIL.index);
    if(tabClicked === NAVBAR_NAMES.PROFILE)     navigate(ROUTES_PATH.PROFILE.index);
  },[]);

  const showTabs = useMemo(() => {
    if(!tabsText.length) return;
    return tabsText.map(item =>{
      return (
        <TabPane tab={item.label} key={item.label}/>
      )});
  },[]);

  useEffect(()=>{
    const removeSlashes = location.pathname.replace(/[^a-zA-Z]/g, "");
    const findName = Object.values(NAVBAR_NAMES).find(item => item.replace(/ /g,"").toLowerCase() === removeSlashes);
    setActiveTab(findName);
  },[location.pathname]);

  const isPricerActive = window.location.pathname.indexOf(ROUTES_PATH.PRICER) > -1;
  return (
    <div className={classnames("header__controls", {"header__controls--expand-to-full-screen-width" : !isPricerActive})}>
      <Tabs
        activeKey={activeTab}
        className='header-tabs'
        onChange={tab => onTabClick(tab)}
      >
        {showTabs}
      </Tabs>
      <Divider type="vertical"  className="vertical--divider"/>
      <h3 className="header--logo">{NAVBAR_NAMES.ARATUS}</h3>

      <div className="header__controls--control-item dropdown__container">
        <div className="logout--menu">
          <Menu mode="horizontal">
            <Menu.SubMenu key="SubMenu" icon={<Avatar>{loginInfo?.name[0].toUpperCase()}</Avatar>}>
              <Menu.Item key="2" onClick={()=> onTabClick(NAVBAR_NAMES.PROFILE)}>
                {  NAVBAR_NAMES.PROFILE }
              </Menu.Item>
              <Menu.Item key="1" onClick={handleOnLogout}>
                { NAVBAR_NAMES.LOGOUT }
              </Menu.Item>

            </Menu.SubMenu>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
