import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer
} from "react";
import PreLoadPagesSpin from "components/PreLoadPagesSpin";
import { ROUTES_PATH } from "routes";
import { useNavigate } from "react-router-dom";
import axios from "api/axios";
import { createBrowserHistory } from "history";

const customHistory = createBrowserHistory().location;
export const GEEKS_USER = "geeks_user";
export const PRICER_OPT_INFO ="pricerOptInfo";
// List of initial state for all global data
export const initialState = {
  loadingOverlay: false,
  isUserLoggedIn: false,
  isUserLoggedOut: false,
  pricerOptInfo : null,
  loginInfo: "",
};

// Actions
const LOADING_OVERLAY        = "LOADING_OVERLAY";
const LOGIN_SUCCESS          = "LOGIN_SUCCESS";
const LOGIN_FAILED           = "LOGIN_FAILED";
const LOGOUT_SUCCESS         = "LOGOUT_SUCCESS";
const SET_PRICER_OPERATIONS  = "SET_PRICER_OPERATIONS";

// Shared Reducer For Global Context
const Reducer = (globalState, action) => {
  switch (action.type) {
    case LOADING_OVERLAY:
      if (action.payload === globalState.loadingOverlay) return globalState;
      return {
        ...globalState,
        loadingOverlay: action.payload,
      };
    case LOGIN_SUCCESS :
      return {
        ...globalState,
        loginInfo: action.payload,
        isUserLoggedIn : true
      };
    case LOGIN_FAILED :
      return {
        ...globalState,
        isUserLoggedIn : false
      };
    case LOGOUT_SUCCESS:
      return {
        ...globalState,
        isUserLoggedIn : false
      };
    case SET_PRICER_OPERATIONS:
      return {
        ...globalState,
        pricerOptInfo : action.payload
      };
    default:
      return globalState;
  }
};

// Global State Which its provide context for children
const GlobalState = ({ children, defaultInitialState = {} }) => {
  const [globalState, dispatch] = useReducer(Reducer, { ...initialState, ...defaultInitialState });
  const navigate = useNavigate();


  // Method used to toggle overlay OB/OFF
  const setLoadingOverlay = useCallback((value) => {
    dispatch({ type: LOADING_OVERLAY, payload: value });
  }, []);

  // Logout user
  const logoutCurrentUser = useCallback(() => {
    dispatch({ type: LOGOUT_SUCCESS });
  }, []);

  const setUserLoginInfo = useCallback((value) => {
    dispatch({ type: LOGIN_SUCCESS, payload: value });
  }, []);

  const setLoginFailed = useCallback((value) => {
    dispatch({ type: LOGIN_FAILED, payload: value });
  }, []);

  const setPricerOperation = useCallback((value) => {
    dispatch({ type: SET_PRICER_OPERATIONS, payload: value });
  }, []);

  const handleLogout = useCallback(() => {
    // Clear related storage and more...
    // userLogout();
    localStorage.removeItem(GEEKS_USER);
    logoutCurrentUser();
  }, []);


  /*
  * Navigate To Home Page If It's Login
  * */
  useEffect(() => {
    const geeksUser = localStorage.getItem(GEEKS_USER);
    function isAuthed(){
      return axios.get('/auth/logged-in',{ headers:{Authorization: `Bearer ${geeksUser?.token}` }}).then((res)=> res.data.message);
    }

    isAuthed().then( res => {
      if(!res?.['logged in']){
        // return navigate(ROUTES_PATH.SIGN_IN.index, { replace: true });
      }
    });


    if(geeksUser){
      const parseData = JSON.parse(localStorage.getItem(GEEKS_USER));
      setUserLoginInfo(parseData);
      return customHistory.pathname !== ROUTES_PATH.SIGN_IN.index && navigate(customHistory.pathname, { replace: true });
    }

    return navigate(ROUTES_PATH.SIGN_IN.index, { replace: true });
  },[]);

  useEffect(()=>{
    if(!globalState.pricerOptInfo) return;
    localStorage.setItem(PRICER_OPT_INFO, JSON.stringify(globalState.pricerOptInfo));
  },[globalState.pricerOptInfo]);

  const contextValue = useMemo(() => {
    return {
      globalState,
      setLoadingOverlay,
      handleLogout,
      setUserLoginInfo,
      setLoginFailed,
      setPricerOperation,
      isLocalStorageHasUserData : localStorage.getItem(GEEKS_USER)
    };
  }, [globalState]);


  /**
   * If loading...
   */
  if (globalState.loadingOverlay) {
    return <PreLoadPagesSpin />;
  }

  return (
    <GlobalContext.Provider value={ contextValue }>
      { children }
    </GlobalContext.Provider>
  );
};

// Create Global Context
export const GlobalContext = createContext(initialState);

// Export Global State Context Component
export default GlobalState;
